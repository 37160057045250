import { computed, onBeforeMount, ref, watchEffect } from "vue";
import { useStore } from "vuex";

const usePaymentInfo = () => {
  const store = useStore();
  const isCancelSubscription = ref(false);
  const isLoadingSubcsription = ref(false) 
  const initialState = {
    cardNumber: "",
    date: new Date(store.getters['shared/currentTimeTimezone']),
    cvc: null,
    name: "",
    zipCode: ""
  };
  const fieldsCreditCard = ref(initialState);
  const creditCard = computed(
    () => store.getters["students/studentCreditCard"]
  );

  const moodPayment = computed(() => store.getters["students/billingPayment"]);
  const showDeleteModal = computed(
    () => store.getters["students/billingDeleteCardModal"]
  );
  const showSuscriptionDeleteModal = computed(
    () => store.getters["students/billingSuscriptionDelete"]
  );

  const togglePayment = (payload) => {
    store.commit("students/billingTooglePayment", {
      ...payload,
    });
  };

  const toggleDeleteCardModal = (payload) => {
    store.commit("students/billingToogleDeleteModal", {
      ...payload,
    });
  };

  const toggleSuscriptionDeleteModal = (payload) => {
    store.commit("students/billingToogleSuscriptionDeleteModal", {
      ...payload,
    });
  };

  const deleteCard = async () => {
    store.commit("students/billingToogleDeleteModal", {
      isOpen: false,
    });
    store.commit("students/billingTooglePayment", {
      hasCreditCard: false,
      mood: "new",
      moodEdit: true
    });
  };

  const deletingCreditCard = () => {
    store.commit("students/billingToogleDeleteModal", {
      isOpen: false,
    });
  };

  const addSubscription = async (payload) => {
    isLoadingSubcsription.value = true;
    let { success } = await store.dispatch("students/billingSuscribe", {
      payload,
    });
    if (success) {
      store.commit("students/billingTooglePayment", {
        hasCreditCard: false,
        mood: "new",
      });
      await store.dispatch("students/getStudentInfo");
      loadServices()
    }
    isLoadingSubcsription.value = false;
  };

  const student = computed(() => store.getters["students/studentInformation"]);

  const saveCreditCard = async (data) => {
    fieldsCreditCard.value = data;
    let errors = 0;
    Object.entries(fieldsCreditCard.value).forEach(([key, value]) => {
      if (fieldsCreditCard.value[key] === "" || !fieldsCreditCard.value[key]) {
        errors++;
      }
    });
    if (errors === 4) {
      store.commit("shared/uiNotificationStatus", {
        status: "error",
        message: "Please enter all requested data (card, date, cvc y name).",
        isOpen: true,
      });
      store.dispatch("shared/resetNotificationStatus");
      return;
    } else {
      const isSuscribe = !student.value.subscription_canceled;
      let exp_month = fieldsCreditCard.value.date.getMonth() + 1 ?? null;
      let exp_year = fieldsCreditCard.value.date.getFullYear();
      let payload = {
        number: fieldsCreditCard.value.cardNumber,
        exp_month,
        type: "card",
        exp_year,
        cvc: fieldsCreditCard.value.cvc,
        card_name: fieldsCreditCard.value.name,
      };

      // await store.dispatch("students/validateCard" , { payload});
      // if(!success) return
      await store.dispatch("students/deleteCard" , { payload});

      if (isSuscribe) {
        store.commit("students/billingTooglePayment", { isLoading: true });

          const resp = await store.dispatch("students/addCard", { payload });
          
          if (resp.success) {
            store.commit("students/billingTooglePayment", {
              hasCreditCard: false,
              mood: "new",
            });
            await store.dispatch("students/getStudentInfo");
          }
          return;
        }
        
        addSubscription(payload);
    }
  };

  /* BILLING HISTORY */
  const history = computed(() => store.getters["students/billingHistory"]);

  onBeforeMount(() => {
    let creditCard = store.getters["students/studentCreditCard"];
    if (Object.entries(creditCard).length === 0) {
      store.commit("students/billingTooglePayment", {
        hasCreditCard: false,
        mood: "new",
      });
    }
  });

  const cancelSubscription = async () => {
    isCancelSubscription.value = true;
    let { success } = await store.dispatch("students/cancelSubscription");
    store.commit("students/billingTooglePayment", { isLoading: true });
    if (success) {
      await store.dispatch("students/getStudentInfo");
      store.commit("students/billingTooglePayment", {
        hasCreditCard: false,
        mood: "new",
        isLoading: false,
        moodEdit:false
      });
      toggleSuscriptionDeleteModal({ isOpen: false });
      fieldsCreditCard.value = initialState;
      loadServices()
    } else {
      store.commit("students/billingTooglePayment", { isLoading: false });
    }
    isCancelSubscription.value = false;
  };

  const loadServices = async () => {
    await store.dispatch("students/billing");
  };

  return {
    isCancelSubscription,
    cancelSubscription,
    creditCard,
    loadServices,
    moodPayment,
    showDeleteModal,
    showSuscriptionDeleteModal,
    fieldsCreditCard,
    togglePayment,
    toggleDeleteCardModal,
    deletingCreditCard,
    isLoadingSubcsription,
    saveCreditCard,
    history,
    deleteCard,
    toggleSuscriptionDeleteModal,
    addSubscription
  };
};

export default usePaymentInfo;
