<template>
  <div class="bg-primary-4 border-r-10 p-2 p-md-4 mt-4">
    <h1 class="fs-4 text-primary-0 fw-bold mb-3">Billing history</h1>
    <data-table
      class="bg-white border-r-10 overflow-hidden"
      :loading="history.isLoading"
      :value="history.data"
      size="normal"
      responsiveLayout="scroll"
    >
      <template #empty>Only available with a credit card. </template>
      <template #loading> Loading billing history. Please wait. </template>
      <column class="d-none"></column>
      <column field="date" header="Automatic payment">
        <template #body="{ data }">
          <span>{{ formatDate(data.date , 'american') }}</span>
        </template>
      </column>
      <column field="payment_status" header="Payment status">
        <template #body="{ data }">
          <div
            class="
              bg-white
              py-1
              px-3
              bl-shadow
              d-flex
              gap-2
              rounded-pill
              w-content
              align-items-center
            "
          >
            <div
              class="status"
              :class="validateStatus(data.payment_status.toLowerCase())"
            ></div>
            <span class="text-neutro-1 first-letter-uppercase">{{
              validateTextStatus(data.payment_status.toLowerCase())
            }}</span>
          </div>
        </template></column
      >
      <column field="amount" header="Amount">
        <template #body="{ data }">
          <div>${{data.amount}}</div>
        </template>
      </column>
    </data-table>

    <!-- <div class="d-flex align-items-center justify-content-end gap-3 mt-4">
      <p class="text-secondary-1 fw-normal">
        Having trouble with your payments
      </p>
      <a
        :href="`mailto:renzo@uniclick.com.pe?Subject=Interesado en una nueva idea de descuento&cc=${userInformation.email}`"
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-4
          d-flex
          align-items-center
          gap-2
        "
      >
        <span class="material-icons"> mail </span>
        Contact us
      </a>
    </div> -->
  </div>
</template>

<script>
import usePaymentInfo from "@/modules/students/composables/Billing/usePaymentInfo";
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useRewards from "@/modules/students/composables/Rewards/useRewards";
import { onMounted, onUpdated, watchEffect } from "vue";
import useInformation from "@/modules/students/composables/useStudentInformation";
import { format } from "date-fns";
export default {
  setup() {
    const { userInformation } = useRewards();
    const { history } = usePaymentInfo();
    const { studentInfo } = useInformation();
    const validateTextStatus = (state) => {
      if (state.toLowerCase() === "confirmed") {
         return "Confirmed";
      }
      if (state.toLowerCase() === "No state") {
         return "No state";
      }
      if (state.toLowerCase() === "payment declined") {
         return "Payment declined";
      }
      return state
    };

    const validateStatus = (status_payment) => {
      if (status_payment.toLowerCase() === "confirmed") {
        return "bg-feedback-success";
      }
      if (status_payment.toLowerCase() === "pending") {
        return "bg-feedback-warning";
      }
      if (status_payment.toLowerCase() === "payment declined") {
        return "bg-feedback-error";
      }
      if (status_payment.toLowerCase() === "payment expired") {
        return "bg-feedback-error";
      }
    };

    return {
      validateStatus,
      history,
      formatDate,
      validateTextStatus,
      userInformation,
    };
  },
};
</script>