<template>
  <h1 class="fs-1 fw-bold text-primary-1">Your payment info</h1>
  <div class="bg-white border-r-10 p-4 mt-4" v-if="!moodPayment.isLoading">
    <payment-info-component :fieldsCreditCard="fieldsCreditCard" />
    <credit-card-component v-if="moodPayment.hasCreditCard" />
    <credit-card-form-component
      v-if="!moodPayment.hasCreditCard"
      :fieldsCreditCard="fieldsCreditCard"
    />
  </div>
  <skeleton
    v-if="moodPayment.isLoading"
    borderRadius="10px"
    height="20rem"
    class="skeleton-dark mt-4"
  />

  <history-component />
</template>

<script>
import CreditCardComponent from "@/modules/students/components/Billing/PaymentCreditCardComponent";
import PaymentInfoComponent from "@/modules/students/components/Billing/PaymentInfoComponent";
import CreditCardFormComponent from "@/modules/students/components/Billing/PaymentCreditCardFormComponent";
import HistoryComponent from "@/modules/students/components/Billing/HistoryComponent";
import usePaymentInfo from "@/modules/students/composables/Billing/usePaymentInfo";

export default {
  components: {
    PaymentInfoComponent,
    CreditCardComponent,
    CreditCardFormComponent,
    HistoryComponent,
  },
  setup() {
    const { moodPayment, creditCard, fieldsCreditCard, loadServices } =
      usePaymentInfo();
    loadServices();
    return { moodPayment, creditCard, fieldsCreditCard };
  },
};
</script>
