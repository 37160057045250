<template>
  <div>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start mb-2">
      <h1 class="text-primary-0 fs-5">Your current credit card</h1>
      <button
        class="btn fs-5 text-accent-1 text-hover-accent-1"
        v-if="moodPayment.mood === 'edit'"
        @click="toggleDeleteCardModal({ isOpen: true })"
      >
        <i class="pi pi-trash"></i>
        Change card
      </button>
    </div>
    <div
      class="border-primary-1 border-r-10 px-4 py-3 bg-primary-5 credit-card"
    >
      <div
        class="
          d-flex
          flex-column
          flex-md-row
          gap-2
          align-items-md-center
          justify-content-between
          text-neutro-1
          py-2
        "
      >
        <div class="d-flex gap-3 align-items-center text-neutro-1">
          <img
            v-if="creditCard.brand"
            :src="
              '/assets/images/credit-card-icons/' +
              creditCard.brand.toLowerCase() +
              '.png'
            "
            class="border-primary-1 w-100 credit-card-img"
            loading="lazy"
          />
          <div v-else class="credit-card-img-empty bg-primary-4"></div>
          <p class="fs-6 fw-bold">XXXX-XXXX-XXXX-{{ creditCard.last4 }}</p>
        </div>
        <div class="d-flex gap-2">
          <p class="fs-6 fw-bold">Titular name:</p>
          <p class="fs-6">{{ creditCard.name }}</p>
        </div>
        <div class="d-flex gap-2">
          <p class="fs-6 fw-bold">Exp. date:</p>
          <p class="fs-6">
            {{
              creditCard.exp_month < 10
                ? "0" + creditCard.exp_month
                : creditCard.exp_month ?? creditCard.month
            }}/{{ ("" + creditCard.exp_year).substr(2) }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="moodPayment.mood === 'edit'">
      <p class="mt-3">
        If you want to update your card details, please delete your current card
        and add another one. In case you want to cancel your subscription,
        <button
          class="btn text-secondary-1 text-hover-secondary-1 p-0 m-0"
          @click="toggleSuscriptionDeleteModal({ isOpen: true })"
        >
          click here.
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import usePaymentInfo from "@/modules/students/composables/Billing/usePaymentInfo";
export default {
  name: "PaymentCreditCardComponent",
  setup() {
    const {
      moodPayment,
      togglePayment,
      toggleDeleteCardModal,
      creditCard,
      toggleSuscriptionDeleteModal,
    } = usePaymentInfo();
    return {
      moodPayment,
      creditCard,
      togglePayment,
      toggleDeleteCardModal,
      toggleSuscriptionDeleteModal,
    };
  },
};
</script>

