<template>
  <div>
    <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
      <h1 class="fs-4 text-primary-0 fw-bold w-100">Credit card</h1>
      <button
        class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          my-3
          my-md-0
          px-4
        "
        v-if="moodPayment.mood === ''"
        @click="togglePayment({ mood: 'edit' })"
      >
        <i class="pi pi-pencil me-2"></i>
        Edit info
      </button>
      <div
        class="d-flex align-items-center justify-content-end gap-3 my-3 w-100"
        v-if="moodPayment.mood === 'edit' || moodPayment.mood === 'new'"
      >
        <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
          "
          v-if="moodPayment.mood === 'edit' && !moodPayment.moodEdit "
          @click="togglePayment({ mood: '' })"
        >
          Cancel
        </button>
         <button
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
          "
          v-if="moodPayment.moodEdit"
          @click="togglePayment({ mood: '' , hasCreditCard: true })"
        >
          Cancel
        </button>
        <button
          class="
            btn
            bl-shadow
            text-white
            bg-secondary-1 bg-hover-secondary-1
            p-2
            px-4
          "
          :disabled="moodPayment.mood !== 'new' || isLoadingSubcsription"
          @click="(e) => saveCreditCard(fieldsCreditCard)"
        >
          Save
        </button>
      </div>
    </div>

    <div
      class="d-flex flex-column flex-md-row gap-3 gap-md-0 justify-content-between mt-2 mb-4"
      v-if="Object.entries(creditCard).length > 0"
    >
      <div class="d-flex w-100 gap-2 align-items-center">
        <p class="fs-6 fw-bold">Status:</p>
        <div
          class="
            payment-status
            rounded-pill
            text-white
            d-flex
            justify-content-center
            align-items-center
            bg-secondary-1
          "
        >
          <p>
            {{ studentInfo.payment_status }}
          </p>
        </div>
      </div>
      <div class="d-flex w-100 gap-2 align-items-center">
        <p class="fs-6 fw-bold">Payment date:</p>
        <p class="fs-6">{{ formatDate(creditCard.pay_day, "paymentInfo") }}</p>
      </div>
      <div class="d-flex w-100 gap-2 align-items-center">
        <p class="fs-6 fw-bold">Member since:</p>
        <p class="fs-6">{{ formatDate(history.data[history.data.length-1]?.date, "paymentInfo") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import usePaymentInfo from "@/modules/students/composables/Billing/usePaymentInfo";
import useStudent from "@/modules/students/composables/useStudentInformation";
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";
import formatDate from "@/shared/utils/changeTexts/formatDate";

export default {
  name: "PaymentInfoComponent",
  props: {
    fieldsCreditCard: {
      type: Object,
    },
  },
  setup() {
    const { togglePayment, moodPayment, saveCreditCard, creditCard , isLoadingSubcsription, history } =
      usePaymentInfo();
    const { dashboard } = useDashboard();
    const { studentInfo,  } = useStudent();

    return {
      togglePayment,
      moodPayment,
      dashboard,
      isLoadingSubcsription,
      creditCard,
      saveCreditCard,
      formatDate,
      studentInfo,
      history
    };
  },
};
</script>

<style lang="scss" scoped>
button {
  max-width: 150px;
  width: 100%;
}
</style>