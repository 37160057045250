<template>
  <div class="w-100 p-1 p-ms-4 mt-3 bg-primary-5 border-r-10">
    <div class="px-2 px-md-5 mt-3">
      <div class="d-flex flex-column flex-md-row align-items-md-center gap-3 justify-content-between">
        <h2 class="text-primary-0 fs-5 m-0">New Credit/Debit Card</h2>
        <img
          alt="credit card icons"
          src="/assets/images/credit-card-icons/cards.png"
          class="w-100"
        />
      </div>
      <div>
        <div class="field mt-2 mb-3">
          <input-text
            id="cardNumber"
            type="text"
            placeholder="Your card"
            class="text-primary-1 field-border-bottom rounded-0"
            v-model="fieldsCreditCard.cardNumber"
            :class="
              isInputFilled(fieldsCreditCard.cardNumber + '')
                ? 'input-fill'
                : ''
            "
          />
        </div>
        <div class="d-flex gap-3 mb-3">
          <div class="field field-border-calendar w-100">
            <Calendar
              :showIcon="false"
              view="month"
              class="w-100 field-border-bottom bg-white"
              placeholder="Date"
              v-model="fieldsCreditCard.date"
              dateFormat="mm/y"
            />
          </div>
          <div class="field w-100">
            <input-text
              placeholder="CVC"
              type="number"
              v-model="fieldsCreditCard.cvc"
              class="rounded-0 w-100 field-border-bottom"
              :class="isInputFilled(fieldsCreditCard.cvc) ? 'input-fill' : ''"
            />
          </div>
        </div>
        <div class="d-flex gap-3 mb-3">
          <div class="field w-100">
            <input-text
              placeholder="Your name"
              type="text"
              name="name"
              v-model="fieldsCreditCard.name"
              class="rounded-0 field-border-bottom"
              :class="isInputFilled(fieldsCreditCard.name) ? 'input-fill' : ''"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isInputFilled from "@/shared/utils/validations/isInputFilled";
import { useStore } from 'vuex'

export default {
  name: "PaymentCreditCardFormComponent",
  props: {
    fieldsCreditCard: {
      type: Object,
      default: () => ({
        cardNumber: "",
        date: "",
        cvc: "",
        name: "",
        zipCode: ""
      }),
    },
  },
  setup() {
    const store = useStore()
    return { isInputFilled, store };
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 180px;
}
</style>